<template>
  <div>
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="账号管理">
        <div class="card" style="display: flex;flex-wrap: wrap;">
            <div class="panel" v-for="item, index in userList" :key="item.user.id">
              <div class="header">
                <div>
                  <img src="@/assets/icons/zhanghu.png">
                  <span class="name">{{ item.user.name }}</span>
                </div>
                <a href="javascript:void(0)" @click="getDetail(item.user.id)">查看</a>
              </div>
              <div class="content">
                <a-checkbox :checked="item.cbList.length == item.cbList.filter(v=>v.own==true).length" @change="onCheckAllChange($event,item,index)">
                  全选
                </a-checkbox>
                <div class="row" v-for="item in item.cbList" :key="item.id">
                  <a-checkbox v-model="item.own"/>
                  <span class="name">{{ item.name }}</span>
                </div>
              </div>
              <div style="text-align: center;position: absolute;bottom: 24px;left: 0;right: 0;">
                <a-button :loading="btnLoading" type="primary" style="border-radius: 24px;padding: 0 44px;" @click="submitChange(index)">确定</a-button>
              </div>
            </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="详情列表" force-render>
        <div class="card">
          <select placeholder="用户名称" v-model="userId" @change="getLogList" style="margin-bottom: 20px;">
            <option v-for="item in userList" :key="item.user.id" :value="item.user.id">
              {{ item.user.name }}
            </option>
          </select>
          <div style="margin-bottom: 20px;">
            <a-radio-group button-style="solid" v-model="type" @change="getLogList" size="large">
              <a-radio-button value="project_build">
                创建项目
              </a-radio-button>
              <a-radio-button value="project_update">
                编辑项目
              </a-radio-button>
              <a-radio-button value="task_add">
                创建任务
              </a-radio-button>
              <a-radio-button value="model_download">
                模型下载
              </a-radio-button>
            </a-radio-group>
          </div>
          <a-skeleton v-if="loading" />
          <div v-else>
            <table class="table">
              <tr class="header">
                <td>项目名称</td>
                <td>时间</td>
                <td v-if="type=='task_add'">生成时间</td>
              </tr>
              <tr v-for="item in logList" :key="item.id" class="row">
                <td>{{ item.fname }}</td>
                <td>{{ item.createAt }}</td>
                <td v-if="type=='task_add'">
                  <span v-if="item.buildTime">{{ item.buildTime }}</span><span v-else>＜1</span>分钟
                </td>
              </tr>
            </table>
            <div style="margin-top: 20px;text-align: right;">
              <a-pagination
                :show-total="total => `共 ${total} 条`"
                show-size-changer
                :current="pageNum"
                :pageSize="pageSize"
                :total="total"
                @change="pageChange"
                @showSizeChange="pageChange"
              />
            </div>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
  
</template>

<script>
function submit(data) {
  return request({
      url: '/dp/cb/submit',
      method: 'post',
      data
  })
}

function fetchUserList() {
  return request({
      url: '/dp/cb/allList',
  })
}

function fetchLogList(params) {
  return request({
      url: '/bim/optLog/list',
      params
  })
}
import request from '@/api/request.js'
export default {
  data() {
    return {
      active: '1',
      userList: [],
      userId: '',
      userName: '',
      type: 'project_build',
      startTime: '',
      endTime: '',
      loading: false,
      btnLoading: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      logList: [],
    };
  },

  computed: {
  },

  watch: {

  },
  mounted() {
    this.getUserList();
  },

  methods: {
    submitChange(index) {
      this.btnLoading = true;
      const currentData = this.userList[index];
      let param = {
        userId: currentData.user.id,
        fidList: currentData.cbList
      }
      submit(param)
        .then((res) => {
          console.log(res)
        })
        .finally(() => {
          this.btnLoading = false;
          this.getUserList();
        });
    },
    pageChange(current, size) {
      this.pageNum = current;
      this.pageSize = size;
      this.getLogList();
    },
    getDetail(id) {
      this.pageNum = 1;
      this.pageSize = 10;
      this.userId = id;
      this.getLogList();
      this.active = '2';
    },
    getUserList() {
      fetchUserList().then((res) => {
        if (Array.isArray(res)) {
          this.userList = res;
          this.userId = res[0].user.id;
          this.getLogList();
        }
      })   
    },
    getLogList() {
      this.loading = true;
      fetchLogList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: this.userId,
        userName: this.userName,
        type: this.type,
        startTime: this.startTime,
        endTime: this.endTime
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.logList = res.list;
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCheckAllChange(e, item, index) {
      console.log(e.target.checked)
      const checked = e.target.checked;
      if(checked){
        item.cbList = item.cbList.map(v=>{
          return {
            ...v,
            own:true
          }
        })
      }else{
        item.cbList = item.cbList.map(v=>{
          return {
            ...v,
            own:false
          }
        })
      }
      this.userList.splice(index, 1, item);
    }
  },
};
</script>

<style lang="less" scoped>
.ant-radio-button-wrapper {
  margin-right: 10px;
  background:#ebf2fc;
  color: #9ca4b1;
  border: none;
  border-radius: 24px;
}
.ant-radio-button-wrapper::before {
  width: 0;
}
select {
  width: 177px;
  height: 40px;
  padding: 0 12px;
  background: #f8f9fb !important;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
}
.card {
  background: #fff;
  box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
  border-radius: 20px;
  padding: 40px;
}
.panel{
  width: 30%;
  margin-right: 3%;
  box-shadow: 0px 0px 13px 0px rgba(82,131,221,0.1);
  background: #FFFFFF;
  border-radius: 6px;
  border: 2px solid #EFF1F6;
  padding: 24px 36px;
  position: relative;
  margin-bottom: 40px;
  .header{
    border-bottom: 1px solid #DAE3EC;
    padding-bottom: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      width: 30px;
      margin-right: 20px;
    }
    .name{
      font-size: 20px;
      font-weight: 500;
      vertical-align: middle;
    }
    a{
      font-size: 16px;
      font-weight: 400;
      color: #066EFD;
    }
  }
  .content{
    .row{
      margin-top: 18px;
      span{
        font-size: 18px;
        font-weight: 500;
        color: #0668FD;
      }
      .name{
        margin-left: 24px;
        margin-right: 16px;
      }
    }
    margin-bottom: 58px;
  }
}
.table {
  width: 100%;
  .header {
    opacity: 0.7;

    td {
      padding: 16px;
      background: #ebf2fc;
      font-weight: 400;
      color: #9ca4b1;
      font-size: 16px;
    }
    td:first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    td:last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }

  .row {
    border-bottom: 1px solid rgba(207, 220, 238, 0.71);

    td {
      padding: 16px;
    }
  }
}

</style>